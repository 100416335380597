import React, { ReactElement } from "react"
import Footer from "./footer"
import Header from "./header"
import { GlobalStyles } from "twin.macro"


type Props = {
  siteTitle: string,
  phone: {
    number: string,
    displayNumber: string
  }
}
const Layout: React.FC<Props> = ({children, siteTitle="", phone}): ReactElement => {
    return (
      <div className="flex flex-col min-h-screen text-sm md:text-base">
        <GlobalStyles />
        <Header
          siteTitle={siteTitle}
          telephone="00436803256330"
          telephoneDisplay="+43 680 3256330"
        />
        <div className="leading-normal tracking-normal text-white bg-grey-300 flex-grow">
          <main>{children}</main>
        </div>
        <Footer siteTitle={siteTitle} />
      </div>
    )
}

export default Layout
