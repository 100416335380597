import React, { ReactElement } from "react"
import { Helmet } from "react-helmet"
import { StaticQuery, graphql } from "gatsby"

type Props = {
  description?: string
  lang?: string
  meta?: any[]
  keywords?: string[]
  title: string
  image?: string
  contactPoint?: {
    [type: string]: string
    telephone: string
    contactType: string
  }
}

const JsonLd: React.FC = ({ children }): ReactElement => {
  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(children)}</script>
    </Helmet>
  )
}

const SEO = ({
  description = "",
  lang = "de",
  meta = [],
  keywords = [],
  title = "",
  image = "",
  contactPoint
}: Props): ReactElement => {
  return (
    <StaticQuery
      query={detailsQuery}
      render={(data) => {
        const metaDescription = description || data.site.siteMetadata.description
        const metaTitle = title || data.site.siteMetadata.title
        const metaUrl = data.site.siteMetadata.siteUrl
        const telephone = data.site.siteMetadata.telephone

        return (
          <Helmet
            htmlAttributes={{
              lang
            }}
            title={title}
            titleTemplate={`%s | ${data.site.siteMetadata.title}`}
            meta={[
              {
                property: `og:title`,
                content: metaTitle
              },
              {
                name: `description`,
                content: metaDescription
              },
              {
                property: `og:description`,
                content: metaDescription
              },
              {
                property: `og:type`,
                content: `website`
              },
              {
                name: `twitter:card`,
                content: `summary`
              },
              {
                name: `twitter:creator`,
                content: data.site.siteMetadata.author
              },
              {
                name: `twitter:title`,
                content: metaTitle
              },
              {
                name: `twitter:description`,
                content: metaDescription
              },
              {
                name: `facebook:image`,
                content: image || data.site.siteMetadata.image
              },
              {
                name: `og:image`,
                content: image || data.site.siteMetadata.image
              },
              {
                name: `og:url`,
                content: metaUrl
              }
            ]
              .concat(
                keywords.length > 0
                  ? {
                      name: `keywords`,
                      content: keywords.join(`, `)
                    }
                  : []
              )
              .concat(meta)}
          >
            <JsonLd>
              {{
                "@context": "https://schema.org",
                "@type": "Organization",
                url: { metaUrl },
                name: { metaTitle },
                contactPoint: {
                  "@type": "ContactPoint",
                  telephone: { telephone },
                  contactType: "Kundendienst"
                }
              }}
            </JsonLd>00
          </Helmet>
        )
      }}
    />
  )
}

export default SEO

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        image
        siteUrl
        telephone
      }
    }
  }
`
