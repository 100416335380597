import React, { useState } from "react"
import styled from "styled-components"
import tw from "twin.macro"
import * as Feather from "react-feather"
import { Link } from "gatsby"

import Logo from "../images/logo.svg"

type Props = {
  siteTitle: string
  telephone: string
  telephoneDisplay: string
}

export default ({ siteTitle, telephone, telephoneDisplay }: Props) => (
  <div className="bg-grey-200">
    <nav className="container px-10 flex items-center justify-between mx-auto pb-4 font-display uppercase">
      <div className="flex items-center text-grey-600 mr-6 flex-grow">
        <Link to={`/`} className="flex items-center">
          <img src={Logo} alt="Logo" className="object-contain w-full mb-0" />
          <span className="ml-4 whitespace-no-wrap font-black font-display">Smarthome König</span>
        </Link>
      </div>
      <div className="hidden md:flex items-end">
        <div className="text-right text-grey-600">
          <a className="hover:text-green-500 flex items-center" href={`tel:${telephone}`}>
            <Feather.Phone />
            <span className="pl-2 text-lg">{telephoneDisplay}</span>
          </a>
        </div>
      </div>
    </nav>
  </div>
)
