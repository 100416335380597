import React from "react"
import styled from "styled-components"
import tw from "twin.macro"
import { Link } from "gatsby"

type Props = {
  siteTitle: string
}

export default ({ siteTitle }: Props) => {
  return (
    <div className="pt-24 pb-4 bg-gray-200 text-center text-gray-700 border-green-500 border-t-4 relative">
      <h3 className="mb-1 text-base font-bold">Zentrale</h3>
      <p className="mb-2 text-sm font-thin">
        Schafberg 6B
        <br />
        4783 Wernstein am Inn
        <br />
        Oberösterreich
      </p>
      <div className="font-thin text-sm mb-8">
        <Link
          to="/impressum"
          className="border-green-500 border-b-2 hover:text-green-500 hover:border-green-500 mb-4"
        >
          Impressum
        </Link>
      </div>
      <p className="mb-2 text-sm font-thin">
        Design & Entwicklung:{" "}
        <a
          className="border-green-500 border-b-2 hover:text-green-500 hover:border-green-500"
          href="https://thok.tech"
        >
          thok.tech
        </a>
        .
      </p>
      <p className="mb-2 text-sm font-thin">
        © {new Date().getFullYear()} {siteTitle}
      </p>
    </div>
  )
}
